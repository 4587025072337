@use 'src/styles/variables' as var;
@use 'src/styles/mixins' as mix;

.row {
  display: flex;
  gap: 8px;
  align-items: center;
  @include mix.row;

  @include mix.vp-440 {
    flex-direction: column;
    align-items: flex-start;
  }

  &Title {
    @include mix.subheading;
  }
}

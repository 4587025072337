@use 'src/styles/variables' as var;
@use 'src/styles/mixins' as mix;

.wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 160px;
  height: 120px;
  background-color: var.$gray-light;
  border-radius: 10px;
  cursor: pointer;
  position: relative;
  gap: 10px;
  @include mix.hover-interaction;
}

.text {
  @include mix.text(16, 500, 18);
  max-width: 100%;
  @include mix.clamp(2);
  height: 36px;
  text-align: center;
}

.delete {
  background-color: var.$white;
  padding: 4px;
  width: 36px;
  height: 36px;
  cursor: pointer;
  border-radius: 50%;
  position: absolute;
  top: -10px;
  right: -10px;
  box-shadow: 0 0 10px 0 var.$black-10;
  opacity: 0;
  transition: opacity 0.3s ease;

  @include mix.vp-1024 {
    opacity: 1;
  }
}

.wrapper:hover {
  .delete {
    opacity: 1;
  }
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

@use 'src/styles/variables' as var;
@use 'src/styles/mixins' as mix;

.skeleton {
  width: 100%;
  height: 24px;
  position: relative;
  overflow: hidden;
  background: var.$gray-light;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    background: linear-gradient(
      90deg,
      transparent 0%,
      var.$white 50%,
      transparent 100%
    );
    animation: shimmer 2s cubic-bezier(0.4, 0, 0.2, 1) infinite;
  }
}

@keyframes shimmer {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(200%);
  }
}

.light {
  background: var.$gray-light;
}

.gray {
  background: var.$gray;

  &::after {
    background: linear-gradient(
      90deg,
      transparent 0%,
      var.$gray-light 50%,
      transparent 100%
    );
  }
}

@use 'src/styles/variables' as var;
@use 'src/styles/mixins' as mix;

.wrapper {
  @include mix.text(32, 800, 36);
  cursor: default;

  @include mix.vp-1024 {
    @include mix.text(24, 800, 28);
  }
}

@use 'src/styles/variables' as var;
@use 'src/styles/mixins' as mix;

.input {
  outline: none;
  border: none;
  color: var.$black;
  width: 140px;
  background-color: transparent;
  resize: none;
  padding: 0;
  display: block;
  box-sizing: border-box;
  overflow: hidden;
  cursor: pointer;
  @include mix.hover-opacity;

  @include mix.vp-1024 {
    width: 128px;
  }

  &[type='date'] {
    font-family: var.$manrope;
  }

  &::-webkit-calendar-picker-indicator {
    cursor: pointer;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  &:hover {
    outline: none;
    border: none;
  }

  &:focus {
    outline: none;
    border: none;
    cursor: text;
    opacity: 1;
  }

  &::placeholder {
    color: var.$black-50;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;

  @include mix.vp-1024 {
    padding: 0;
  }
}

.container {
  position: relative;
  width: 100%;
  transition: all 0.3s ease;
}

.errorTag {
  color: var.$danger;
  @include mix.text(12, 400, 16);
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}

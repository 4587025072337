@use 'src/styles/variables' as var;
@use 'src/styles/mixins' as mix;

.row {
  display: flex;
  gap: 4px;
  @include mix.row;
  flex-direction: column;

  &Title {
    @include mix.subheading;
    display: none;
  }
}

.labels {
  display: flex;
  align-items: center;
  gap: 8px;
  flex-wrap: wrap;
}

.container {
  position: relative;
}

div.dropdown {
  width: 340px;
  flex-wrap: wrap;
  flex-direction: row;

  @include mix.vp-767 {
    width: 100%;
  }
}

div.addLabel {
  color: var.$white;
}

@use 'src/styles/variables' as var;
@use 'src/styles/mixins' as mix;

.list {
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  @include mix.block;
  padding: 16px;
  width: fit-content;
  z-index: 1000;
}

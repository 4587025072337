@use 'src/styles/variables' as var;
@use 'src/styles/mixins' as mix;

.status {
  @include mix.text(20, 500, 24);
  cursor: pointer;
  @include mix.hover-opacity;
  user-select: none;
  background-color: var.$gray-light;
  padding: 4px 8px;
  border-radius: 6px;
  width: fit-content;
  white-space: nowrap;

  @include mix.vp-1024 {
    @include mix.text(18, 500, 20);
  }
}

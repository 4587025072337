@use 'src/styles/variables' as var;
@use 'src/styles/mixins' as mix;

.wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 16px;
  @include mix.block;
  gap: 16px;

  @include mix.vp-767 {
    flex-direction: column;
  }
}

.title {
  @include mix.text(18, 800, 18);
}

.text {
  @include mix.text(16, 500, 16);
}

div.status {
  @include mix.text(16, 500, 16);
}

@use 'src/styles/variables' as var;
@use 'src/styles/mixins' as mix;

.wrapper {
  flex-direction: column;
  gap: 0;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 20px;

  @include mix.vp-767 {
    flex-direction: column;
  }
}

.area {
  padding: 0;
  background-color: var.$gray-light;
  border-radius: 10px;
  width: 100%;
}

.slider {
  width: 100%;
}

.inputFile {
  margin: 0 auto;
  max-width: 250px;
}

.imageWrapper {
  padding: 20px 20px 0 0;
}

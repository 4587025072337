@use 'src/styles/variables' as var;
@use 'src/styles/mixins' as mix;

.row {
  display: flex;
  gap: 4px;
  @include mix.row;
  flex-direction: column;
}

.title {
  @include mix.text(20, 800, 24);
  white-space: nowrap;

  @include mix.vp-1024 {
    @include mix.text(18, 800, 20);
  }
}

.input {
  @include mix.text(20, 500, 24);

  @include mix.vp-1024 {
    @include mix.text(18, 500, 20);
  }
}

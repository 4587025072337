@use 'src/styles/variables' as var;
@use 'src/styles/mixins' as mix;

.more {
  cursor: pointer;
  @include mix.hover-opacity;
  background-color: var.$gray-light;
  padding: 4px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.light {
  background-color: var.$white;
}

.gray {
  background-color: var.$gray-light;
}

.dark {
  background-color: var.$dark;
}

@use 'src/styles/variables' as var;
@use 'src/styles/mixins' as mix;

.wrapper {
  display: flex;
  align-items: center;
  gap: 8px;
}

.link {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 4px 8px;
  border-radius: 6px;
  background-color: var.$gray-light;
  cursor: pointer;
  @include mix.hover-opacity;
  width: fit-content;
  white-space: nowrap;
}

.icon {
  width: 18px;
  height: 18px;
}

.text {
  @include mix.text(20, 500, 24);

  @include mix.vp-1024 {
    @include mix.text(18, 500, 20);
  }
}

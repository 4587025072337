@use 'src/styles/variables' as var;
@use 'src/styles/mixins' as mix;

.wrapper {
  @include mix.block;
  padding: 40px 60px;
  display: flex;
  gap: 20px;

  @include mix.vp-1024 {
    padding: 20px;
  }

  &.light {
    background-color: var.$white;
  }

  &.dark {
    background-color: var.$black;
  }

  &.gray {
    background-color: var.$gray-light;
  }
}

@use 'src/styles/variables' as var;
@use 'src/styles/mixins' as mix;

.button {
  width: fit-content;
  @include mix.text(16, 500, 16);
  cursor: pointer;
  background-color: var.$white;
  border: 1px solid var.$gray;
  padding: 20px 18px;
  border-radius: 100px;
  color: var.$black;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  @include mix.hover-opacity;
  gap: 8px;

  @include mix.vp-1024 {
    padding: 16px 10px;
  }
}

.dark {
  background-color: var.$black;
  color: var.$white;
  border: none;
}

.ghost {
  background-color: transparent;
  color: var.$black;
  border: none;
  padding: 12px;
}

.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.icon {
  width: 20px;
  height: 20px;
}

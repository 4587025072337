@use 'src/styles/variables' as var;
@use 'src/styles/mixins' as mix;

.label {
  @include mix.text(20, 500, 24);
  cursor: pointer;
  @include mix.hover-opacity;
  padding: 4px 8px;
  border-radius: 6px;
  background-color: var.$gray-light;
  width: fit-content;
  user-select: none;
  min-width: 35px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  position: relative;
  white-space: nowrap;

  &:hover {
    .cross {
      opacity: 1;
    }
  }

  @include mix.vp-1024 {
    @include mix.text(18, 500, 20);
  }
}

.cross {
  width: 16px;
  height: 16px;
  min-width: 12px;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  position: absolute;
  right: -7px;
  top: -6px;
}

@use 'src/styles/variables' as var;
@use 'src/styles/mixins' as mix;

.wrapper {
  @include mix.text(16, 400, 24);
  color: var.$gray-dark;
  @include mix.block;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 150px;
}

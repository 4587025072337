@use 'src/styles/variables' as var;
@use 'src/styles/mixins' as mix;

.main {
  @include mix.about-block;
}

.wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  gap: 16px;

  @include mix.vp-1024 {
    flex-direction: column;
  }
}

.sidebar {
  background: var.$gray;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  width: 220px;
  min-width: 220px;
  height: 220px;
  justify-content: space-between;

  @include mix.vp-1024 {
    width: 100%;
    height: 150px;
    min-width: 100%;
  }
}

.logo {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.rows {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.lastRow {
  @include mix.vp-1024 {
    display: none;
  }
}

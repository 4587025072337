@use 'src/styles/variables' as var;
@use 'src/styles/mixins' as mix;

.wrapper {
  position: relative;
  z-index: 0;
  margin-bottom: 150px;

  @include mix.vp-1024 {
    margin-bottom: 80px;
  }
}

.content {
  width: 100%;
  display: flex;
  background-color: var.$white;
  padding: 32px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 600px;

  @include mix.vp-1024 {
    height: 500px;
    padding: 16px;
  }
}

.slider {
  width: 100%;
  position: relative;
  max-width: 1100px;
  width: 100%;
  margin: 0 auto;
  padding: 0 10px !important;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
    width: 50px;
    height: 100%;
    z-index: 2;
    pointer-events: none;

    @include mix.vp-767 {
      display: none;
    }
  }

  &::before {
    left: 0;
    background: linear-gradient(to right, var.$white, transparent);
  }

  &::after {
    right: 0;
    background: linear-gradient(to left, var.$white, transparent);
  }
}

.slide {
  padding: 0 20px;

  @include mix.vp-767 {
    padding: 0 10px;
  }
}

.stageWrapper {
  position: absolute;
  width: 900px;
  height: 80%;
  background: var.$white;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: slideInRight 0.5s ease-in-out forwards;

  &.exit {
    animation: slideOutLeft 0.5s ease-in-out forwards;
  }

  @include mix.vp-1024 {
    width: 100%;
    padding: 0 16px;
  }
}

@keyframes slideInRight {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideOutLeft {
  from {
    transform: translateX(0);
    opacity: 1;
  }
  to {
    transform: translateX(-100%);
    opacity: 0;
  }
}

@keyframes swayLeft {
  0%,
  100% {
    transform: translateY(-50%) rotate(90deg) translateX(0);
  }
  50% {
    transform: translateY(-50%) rotate(90deg) translateX(-10px);
  }
}

@keyframes swayRight {
  0%,
  100% {
    transform: translateY(-50%) rotate(-90deg) translateX(0);
  }
  50% {
    transform: translateY(-50%) rotate(-90deg) translateX(10px);
  }
}

.arrow {
  position: absolute;
  top: 50%;
  cursor: pointer;
  z-index: 10;
  width: 40px;
  height: 40px;
  left: 20px;
  animation: swayRight 5s ease-in-out infinite;
  @include mix.hover-opacity;

  @include mix.vp-1024 {
    display: none;
  }
}

.right {
  left: auto;
  right: 20px;
  animation: swayLeft 5s ease-in-out infinite;
}

.gradient {
  position: absolute;
  top: -200px;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: -1;
  width: 800px;
  height: 800px;

  @include mix.vp-1024 {
    width: 400px;
    height: 400px;
    top: -80px;
  }
}

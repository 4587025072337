@use 'src/styles/variables' as var;
@use 'src/styles/mixins' as mix;

.wrapper {
  width: 100%;
  display: flex;
  background-color: transparent;
  border-bottom: 1px solid var.$black;
  justify-content: space-between;
  align-items: center;
  padding: 16px 32px;
  position: sticky;
  top: 0;
  z-index: 100;
  transition: all 0.3s;

  &:hover {
    background-color: rgba(var.$gray, 0.8);
    backdrop-filter: blur(10px);
    border-bottom: 1px solid var.$black;
  }

  @include mix.vp-1024 {
    display: none;
  }
}

.scrolled {
  border-bottom: 1px solid transparent;
}

.logoText {
  transition: opacity 0.3s;
}

.controls {
  display: flex;
  align-items: center;
  gap: 64px;
}

.links {
  display: flex;
  align-items: center;
  gap: 24px;
  opacity: 1;
  transition: opacity 0.3s;
}

.cta {
  width: 200px;
}

.link {
  cursor: pointer;
  opacity: 1;
  @include mix.hover-opacity;
}

.hidden {
  opacity: 0;

  &:hover {
    opacity: 1;
  }
}

.visible {
  opacity: 1;
}

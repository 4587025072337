@use 'src/styles/variables' as var;
@use 'src/styles/mixins' as mix;

.wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
  width: 100%;

  @include mix.vp-1024 {
    grid-template-columns: repeat(1, 1fr);
  }
}

.container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  @include mix.block;
  padding: 24px;
  @include mix.hover-interaction;
  background-color: var.$gray-light;
  min-height: 292px;

  @include mix.vp-1024 {
    min-height: 394px;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  @include mix.text(20, 800, 20);
}

.board {
  background-color: transparent;
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 100%;
}

.arrow {
  width: 24px;
  height: 24px;
  transform: rotate(90deg);
}

@use 'src/styles/variables' as var;
@use 'src/styles/mixins' as mix;

.wrapper {
  flex-direction: column;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.heading {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  @include mix.text(32, 800, 36);

  @include mix.vp-1024 {
    @include mix.text(24, 800, 28);
  }
}

.description {
  @include mix.text(20, 400, 24);

  @include mix.vp-1024 {
    @include mix.text(18, 400, 20);
  }
}

.rows {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;

  @include mix.vp-1024 {
    grid-template-columns: 1fr;
  }
}

.actions {
  display: flex;
  gap: 16px;
  flex-direction: column;
  width: 200px;
  padding: 12px 4px;

  @include mix.vp-1024 {
    padding: 16px;
    gap: 20px;
  }
}

.actionButton {
  @include mix.vp-1024 {
    display: none;
  }
}

.actionButtonMobile {
  display: none;

  @include mix.vp-1024 {
    display: block;
    width: 100%;
  }
}

div.additional {
  flex-direction: column;
  gap: 4px;
}

div.labels {
  flex-direction: column;
  gap: 4px;
}

@use 'src/styles/variables' as var;
@use 'src/styles/mixins' as mix;

.wrapper {
  display: flex;
  align-items: center;
  overflow: hidden;
  gap: 12px;
}

.text {
  @include mix.text(16, 400, 20);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.icon {
  width: 24px;
  height: 24px;
}

@use 'src/styles/variables' as var;
@use 'src/styles/mixins' as mix;

.wrapper {
  display: grid;
  gap: 20px;
  position: relative;

  &:hover {
    z-index: 10;
  }

  @include mix.vp-1024 {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
}

.actions {
  display: flex;
  gap: 16px;
  flex-direction: column;
  width: 200px;
  padding: 12px 4px;

  @include mix.vp-1024 {
    padding: 16px;
    gap: 20px;
  }
}

.separate {
  @include mix.block;
  @include mix.hover-interaction;
  min-height: 60px;
  padding: 16px;
}

.actionButton {
  @include mix.vp-1024 {
    display: none;
  }
}

.actionButtonMobile {
  display: none;

  @include mix.vp-1024 {
    display: block;
  }
}

@use 'src/styles/variables' as var;
@use 'src/styles/mixins' as mix;

.wrapper {
  display: flex;
  flex-direction: column;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.close {
  display: none;
  cursor: pointer;

  @include mix.vp-1024 {
    display: block;
  }
}

.actions {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.title {
  @include mix.text(32, 800, 36);

  @include mix.vp-1024 {
    @include mix.text(24, 800, 28);
  }
}

.description {
  @include mix.text(20, 400, 24);

  @include mix.vp-1024 {
    @include mix.text(18, 400, 20);
  }
}

.headings {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.rows {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

@use 'src/styles/variables' as var;
@use 'src/styles/mixins' as mix;

.wrapper {
  width: 100%;
  background-color: var.$black;
  color: var.$white;
  display: flex;
  justify-content: space-between;
  padding: 32px;
  flex-direction: column;
  gap: 20px;

  @include mix.vp-1024 {
    height: fit-content;
  }
}

.main {
  margin: 0 auto;
  width: 100%;
  max-width: 1024px;
  display: flex;
  justify-content: space-between;
  height: fit-content;
  gap: 32px;

  @include mix.vp-1024 {
    flex-direction: column;
  }
}

.content {
  display: flex;
  width: 100%;
  gap: 80px;
  height: fit-content;

  @include mix.vp-1024 {
    flex-direction: column;
    gap: 20px;
  }
}

.copyright {
  width: 100%;
  color: var.$gray;
  text-align: left;

  @include mix.vp-1024 {
    text-align: left;
  }
}

.link {
  @include mix.text(16, 300, 16);
  text-decoration: none;
  color: var.$white;
  white-space: nowrap;
  line-height: 24px;
  cursor: pointer;
  width: fit-content;
  @include mix.hover-opacity;
}

.column {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.links {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
}

button.button {
  width: 200px;
  border: 1px solid var.$white;
  background-color: transparent;
  color: var.$white;
}

.contact {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-decoration: none;
  color: var.$black;
  opacity: 1;
  padding: 4px;
  width: 40px;
  height: 40px;
  @include mix.hover-opacity;
}

.contactIcon {
  width: 100%;
  height: 100%;
}

.contactValue {
  @include mix.text(16, 300, 16);
  color: var.$white;
}

.contacts {
  display: flex;
  gap: 16px;
  align-items: center;
  flex-wrap: wrap;
  width: fit-content;
}

@use 'src/styles/variables' as var;
@use 'src/styles/mixins' as mix;

.wrapper {
  width: 500px;
  background-color: var.$gray;
  height: 100vh;
  padding: 20px 20px 100px;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 100;
  overflow: auto;

  &::-webkit-scrollbar {
    display: none;
  }

  @include mix.vp-767 {
    display: none;
  }
}

.mobile {
  max-height: 80vh;
  overflow: auto;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 100;
  padding: 20px 20px 100px;
  background-color: var.$gray;
  border-radius: 10px 10px 0 0;
  display: none;

  &::-webkit-scrollbar {
    display: none;
  }

  @include mix.vp-767 {
    display: block;
  }
}

div.backdrop {
  z-index: 50;
}
